<template>
  <main class="xrlibrary">  
    <div class="loaderanimation" v-show="$store.state.drupalExperiences.length == 0"></div>

    <div v-if="drupalExperience" style="font-size:18px;" class="experience">
      <div style="display:flex; justify-content: space-between;">
        <h1>{{drupalExperience.title}}</h1>
        <div>
          
          <a v-show="drupalExperience.playbook_link" target="_blank" :href="playbookSrc(drupalExperience)" style="text-decoration:none; margin-right: 14px;">
            <button style="line-height:24px;" class="white" :disabled="!drupalExperience.playbook_link">
            <img src="../assets/playbook.png" style="width: 24px; height:24px; vertical-align:middle;"> <span style="vertical-align:middle;">Playbook</span>
            </button>
          </a>
          
          <button class="white" :disabled="!experience" @click="scheduleSession">
            <img src="../assets/calendar.svg" style="width: 24px; height:24px; vertical-align:middle;"> <span style="vertical-align:middle;">Schedule session</span>
          </button>
        </div>
      </div>
      <div>
        {{stripHtml(drupalExperience.teaser.description)}}
      </div>
      <h1 style="margin-top:84px;">Description</h1>
      <div style="color: #777777; line-height:1.61;">{{stripHtml(drupalExperience.description)}}</div>
      <div style="display:grid; grid-template-columns: 1fr 1fr; gap: 60px; margin-top:95px;">
        <div>
          <iframe
            :src="kalturaSrc(drupalExperience)"
            style="aspect-ratio: 734 / 447; width: 100%; max-width:100%;"
            frameborder="0" 
            allowfullscreen="allowfullscreen" 
          >
          </iframe>
          <!-- <iframe
            src="https://cdnapisec.kaltura.com/p/2296822/sp/229682200/embedIframeJs/uiconf_id/39810392/partner_id/2296822?iframeembed=true&amp;entry_id=1_o8glc5zc"
            style="aspect-ratio: 734 / 447; width: 100%; max-width:100%;"
            frameborder="0" 
            allowfullscreen="allowfullscreen" 
          >
          </iframe> -->
          <!-- <iframe width="854" height="480" frameborder="0" allowfullscreen="allowfullscreen" src="https://cdnapisec.kaltura.com/p/2296822/sp/229682200/embedIframeJs/uiconf_id/39810392/partner_id/2296822?iframeembed=true&amp;playerId=kaltura_player&amp;entry_id=1_o8glc5zc&amp;flashvars[streamerType]=auto&amp;amp;flashvars[localizationCode]=en&amp;amp;flashvars[leadWithHTML5]=true&amp;amp;flashvars[autoPlay]=false&amp;amp;flashvars[sideBarContainer.plugin]=true&amp;amp;flashvars[sideBarContainer.position]=left&amp;amp;flashvars[sideBarContainer.clickToClose]=true&amp;amp;flashvars[chapters.plugin]=true&amp;amp;flashvars[chapters.layout]=vertical&amp;amp;flashvars[chapters.thumbnailRotator]=false&amp;amp;flashvars[streamSelector.plugin]=true&amp;amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;amp;flashvars[dualScreen.plugin]=true"></iframe> -->
          <!-- <iframe width="854" height="480" frameborder="0" allowfullscreen="allowfullscreen" src="https://cdnapisec.kaltura.com/p/2296822/sp/229682200/embedIframeJs/uiconf_id/39810392/partner_id/2296822?iframeembed=true&amp;playerId=kaltura_player&amp;entry_id=1_91jcxpi5&amp;flashvars[streamerType]=auto&amp;amp;flashvars[localizationCode]=en&amp;amp;flashvars[leadWithHTML5]=true&amp;amp;flashvars[autoPlay]=false&amp;amp;flashvars[sideBarContainer.plugin]=true&amp;amp;flashvars[sideBarContainer.position]=left&amp;amp;flashvars[sideBarContainer.clickToClose]=true&amp;amp;flashvars[chapters.plugin]=true&amp;amp;flashvars[chapters.layout]=vertical&amp;amp;flashvars[chapters.thumbnailRotator]=false&amp;amp;flashvars[streamSelector.plugin]=true&amp;amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;amp;flashvars[dualScreen.plugin]=true"></iframe> -->
          <!-- <div id="kalturawtf" style="width: 640px;height: 360px"></div>
          <script
            type="application/javascript"
            src="https://cdnapisec.kaltura.com/p/2296822/sp/229682200/embedIframeJs/uiconf_id/39810292?targetId=kalturawtf&entry_id=1_9var80ug"
          ></script> -->
          <!-- <img v-if="experience.video" :src="portalHost + experience.video.video_poster_image" style="max-width: 100%;" /> -->
          <div style="color: #57af80; margin-top:15px;">            
              {{drupalExperience.tags ? drupalExperience.tags.join(', ') : ''}}            
          </div>
        </div>
        <div>
          <h1>Learning Objectives</h1>
          <ul>
            <li v-for="objective in drupalExperience.learning_objectives" :key="objective">{{objective}}</li>
          </ul>
          <h1 style="margin-top:60px;">Unique Features</h1>
          <ul>
            <li v-for="feature in drupalExperience.unique_features" :key="feature">{{feature}}</li>
          </ul>
        </div>
      </div>
      <div style="display:grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; gap: 60px; margin-top:90px;">
        <div>
          <img src="../assets/interactive.svg">
          <div style="font-weight: bold; padding: 10px 0 12px 0;">Type of experience</div>
          <div style="color: #777777;">{{drupalExperience.video_detail ? drupalExperience.video_detail.type_of_experience[0] : ''}}</div>
        </div>
        <div>
          <img src="../assets/time.svg">
          <div style="font-weight: bold; padding: 10px 0 12px 0;">Recommended Learning Time</div>
          <div style="color: #777777;">{{drupalExperience.video_detail ? drupalExperience.video_detail.recommended_learning_time : ''}}</div>
        </div>
        <div>
          <img src="../assets/time.svg">
          <div style="font-weight: bold; padding: 10px 0 12px 0;">Duration of VR</div>
          <div style="color: #777777;">{{drupalExperience.video_detail ? drupalExperience.video_detail.duration_of_vr_seconds : ''}} seconds</div>
        </div>
        <div>
          <img src="../assets/creator.svg">
          <div style="font-weight: bold; padding: 10px 0 12px 0;">Creators</div>
          <div style="color: #777777;" v-for="creator in drupalExperience.video_detail ? drupalExperience.video_detail.creators : []" :key="creator">{{creator}}</div>
        </div>
        <div>
          <img src="../assets/faculty.svg">
          <div style="font-weight: bold; padding: 10px 0 12px 0;">Teaching Faculty</div>
          <div style="color: #777777;">-</div>
        </div>
      </div>
      <h1 style="margin:120px 0 50px 0;">Experience segments - {{drupalExperience.cards ? drupalExperience.cards.length : 0}}</h1>
      <div class="segments" v-if="drupalExperience.cards">
        <div v-for="(segment, idx) in drupalExperience.cards" :key="idx" class="segment" :style="{ backgroundImage: `linear-gradient(transparent 50%, rgba(0, 0, 0, 0.7)), url(${segment.background_image})`}">
          <div class="bottom">
            {{segment.card_sub_title}}
            <br>
            <b>{{segment.card_title}}</b>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <h2>XR Library</h2> 
      <input class="search" v-model.trim="searchTerm" type="text" style="width: 300px;" :disabled="loading" autocomplete="off" placeholder="Search..." /> 
      <img src="../assets/spinner.svg" v-if="loading" style="width: 50px; height: 50px; position: fixed; left: calc(50% - 25px); top: 40%;" />
      <div class="grid">
        <div class="item" v-for="exp in filteredDrupalExperiences" :key="exp.title">          
          <div class="image" :style="{ backgroundImage: `url(${exp.teaser.teaser_image})`}">
            <!-- <img src="~assets/images/dummy.jpg" /> -->
          </div>
          <div class="info">
            <div class="chips">            
                {{exp.tags ? exp.tags.join(', ') : ''}}            
            </div>
            <div class="title">{{exp.title}}</div>
            <div class="description">{{stripHtml(exp.teaser.description.substring(0,126))}}...</div> 
            <router-link :to="`/xrlibrary/${exp.id}`"></router-link>
          </div>
        </div>
      </div>
    </div>

     <ScheduleSession v-show="scheduleSessionModal" ref="editor" @close="scheduleSessionModal = false;" @created="sessionCreated" 
      :headerTitle="'Schedule session'"
      :experience="experience"
     />

     <Modal2 v-if="sessionScheduledModal" @close="sessionScheduledModal = false;">      
      <div slot="body" style="width: 336px; text-align:center;">
        <img style="margin-top:74px;" src="../assets/mail.svg">
        <div>
          <div style="font-size:20px; padding:50px 0 25px 0;">Great!</div>          
          
          <div v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin">
            The session was created successfully. 
            <br>
            You will be reminded a week before the session.
          </div>
          <div v-else>
            Your session has been created successfully.
            <br>
            An admin will be notified to prepare the session.
          </div>
        </div>
      </div>
      <div slot="footer">
        <button class="white right" @click="sessionScheduledModal = false;">Continue</button>
      </div>
    </Modal2>
  </main>
</template>

<script>
//import axios from 'axios'
import Modal2 from '../components/Modal2.vue'
import ScheduleSession from '../components/ScheduleSession.vue'

export default {
  name: 'XRLibrary',
  data: function(){
    return {      
      searchTerm: undefined,
      loading: false,
      error: undefined,
      scheduleSessionModal: false,
      sessionScheduledModal: false
    }
  },
  components: {
    Modal2,
    ScheduleSession
  },
  watch:{
    drupalExperience: function(newItem){
      if(newItem)
        window.scrollTo(0, 0);
    },
    scheduleSessionModal: function(){      
      document.querySelector('.schedule-session .modal2-body').scrollTop = 0      
    }   
  },
  computed: {  
    filteredDrupalExperiences(){
      return this.$store.state.drupalExperiences.filter(e => !this.searchTerm || e.title.toLowerCase().includes(this.searchTerm.toLowerCase()) || e.teaser.description.toLowerCase().includes(this.searchTerm.toLowerCase()))
    },
    drupalExperience(){
      return this.$store.state.drupalExperiences.find(e => e.id == this.$route.params.experienceId)
    },
    experience(){
      return this.$store.state.experiences.find(e => this.drupalExperience && e.cmsId == Number(this.drupalExperience.id))
    },
    portalHost(){
      return process.env.VUE_APP_PORTAL_URL
    }    
  },
  methods: { 
    scheduleSession(){
      this.$refs.editor.create()
      this.scheduleSessionModal = true
    },
    sessionCreated(){      
      this.scheduleSessionModal = false
      this.sessionScheduledModal = true
    },
    playbookSrc(experience){
      return experience.playbook_link ? `${this.portalHost}${experience.playbook_link}` : null
    },
    kalturaSrc(experience){
      return `https://cdnapisec.kaltura.com/p/${experience.video.video_kaltura[0].partner_id}/embedPlaykitJs/uiconf_id/${'52707132'}?iframeembed=true&entry_id=${experience.video.video_kaltura[0].entry_id}&config[playback]={"autoplay":false}`
      //return `https://cdnapisec.kaltura.com/p/${experience.video.video_kaltura[0].partner_id}/sp/${experience.video.video_kaltura[0].partner_id}00/embedIframeJs/uiconf_id/39810392/partner_id/${experience.video.video_kaltura[0].partner_id}?iframeembed=true&entry_id=${experience.video.video_kaltura[0].entry_id}`
      //return `https://cdnapisec.kaltura.com/p/${experience.video.video_kaltura[0].partner_id}/sp/${experience.video.video_kaltura[0].partner_id}00/embedIframeJs/uiconf_id/${experience.video.video_kaltura[0].uiconf_id}/partner_id/${experience.video.video_kaltura[0].partner_id}?iframeembed=true&amp;playerId=kaltura_player&amp;entry_id=${experience.video.video_kaltura[0].entry_id}&amp;flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[autoPlay]=false&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true`
      //return `https://cdnapisec.kaltura.com/p/${experience.video.video_kaltura[0].partner_id}/sp/${experience.video.video_kaltura[0].partner_id}00/embedIframeJs/uiconf_id/${experience.video.video_kaltura[0].uiconf_id}/partner_id/${experience.video.video_kaltura[0].partner_id}?iframeembed=true&playerId=kaltura_player&entry_id=${experience.video.video_kaltura[0].entry_id}&flashvars[streamerType]=auto&flashvars[localizationCode]=en&flashvars[leadWithHTML5]=true&flashvars[autoPlay]=false&flashvars[sideBarContainer.plugin]=true&flashvars[sideBarContainer.position]=left&flashvars[sideBarContainer.clickToClose]=true&flashvars[chapters.plugin]=true&flashvars[chapters.layout]=vertical&flashvars[chapters.thumbnailRotator]=false&flashvars[streamSelector.plugin]=true&flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&flashvars[dualScreen.plugin]=true`
      //return `https://cdnapisec.kaltura.com/p/${experience.video.video_kaltura[0].partner_id}/embedPlaykitJs/uiconf_id/${experience.video.video_kaltura[0].uiconf_id}?autoembed=true&targetId=${experience.video.video_kaltura[0].entry_id}&entry_id=${/*experience.video.video_kaltura[0].entry_id*/"1_9var80ug_ifp"}&config[playback]={"autoplay":true}`
    },
    stripHtml(html){
      return html.replace(/(<([^>]+)>)/gi, "").replace(/(&[^&]+;)/gi,"")
    }
  }, 
  async mounted() {
    try{
      this.$nprogress.start();
      this.loading = true;      
      if(this.$store.state.drupalExperiences?.length == 0)
        await this.$store.dispatch('getDrupalExperiences');
    }
    finally{
      this.loading = false;
      this.$nprogress.done();
      this.$store.state.showLoader = false
    }
  },
  created() {
  }
}
</script>

<style lang="scss">

main.xrlibrary{
  padding: 65px 88px;
  display: block;
  position: relative;

  ul{
    padding: 0;
    padding-left: 3px;
    list-style-position: inside;
    li{
      line-height: 1.33;
      margin-top: 10px;
      color: #777;
    }
  }

  h1{
    font-size: 40px;
  }

  h2{
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 30px 0;
  }

  .experience{
    .segments{
      display:grid; 
      grid-template-columns: 1fr 1fr 1fr 1fr; 
      gap: 30px;

      @media (max-width: 1980px) {        
        grid-template-columns: 1fr 1fr 1fr;        
      }

      .segment{
        aspect-ratio: 488 / 279;
        border-radius: 10px;
        background-size: cover;
        background-position: center center;  
        position: relative;

        .bottom{
          border-radius: 0 0 10px 10px;
          line-height: 1.4;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 70px;
          padding-left: 20px;
          padding-top: 5px;
          color: white;
          //padding-left: 90px;
          // background-image: url(../assets/play.webp);
          // background-position: left;
          // background-repeat: no-repeat;
          // background-size: contain;
        }
      }
    }
  }

  .search{
    position: absolute;
    right: 88px;
    top: 65px;
  }

  .grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr; //repeat(auto-fill, minmax(200px, 1fr));
    /* This is better for small screens, once min() is better supported */
    /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
    gap: 30px;

    @media (max-width: 1980px) {        
        grid-template-columns: 1fr 1fr 1fr;        
    }

    @media (max-width: 1200px) {        
        grid-template-columns: 1fr 1fr;        
    }

    .item{
      border-radius: 10px;
      box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      border-bottom: 15px solid #57af80;
      padding-bottom: 47px;
      position: relative;

      .info{
        padding: 22px 71px 0 28px;
      }

      .image{
        aspect-ratio: 487 / 284;
        border-radius: 10px 10px 70px 10px;  
        background-size: cover;
        background-position: center center;  
      }

      .chips{
        font-size: 14px;
        font-weight: 300;
        color: #57af80;
      }

      .title{
        font-size: 19px;
        font-weight: bold;
        color: #1c2628;
        padding: 15px 0 24px 0;
      }

      a{
        background-image: url(../assets/library-arrow.jpg);
        background-size:contain;
        display: block;
        width: 60px;
        height: 47px;
        position: absolute;
        bottom: 0;
        right: 0;        
        border-radius: 10px 0 0 0;
      }
    }
  }
}

</style>
